var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hear-toggle" },
    [
      _c("div", { staticClass: "hear-toggle__title" }, [_vm._v("Users Hear:")]),
      _c("RtbToggleButton", {
        style: {
          height: "32px",
          width: "150px",
          marginRight: "4px",
        },
        attrs: { items: _vm.items },
        model: {
          value: _vm.value,
          callback: function ($$v) {
            _vm.value = $$v
          },
          expression: "value",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }