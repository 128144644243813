















import Vue from "vue"
import { mapState } from "vuex"
import { RtbToggleButton } from "@/components/ui"

import { db } from "@/firebase"

import AudioOption from "@shared/enums/AudioOverride"

const Value = {
  AUTO: "Auto",
  ALL: "All"
}

const items = [{ name: Value.AUTO }, { name: Value.ALL }]

export default Vue.extend({
  name: "HearToggle",
  components: { RtbToggleButton },
  computed: {
    ...mapState(["gameID", "orgID"]),
    ref() {
      return db
        .auxiliary()
        .ref(`org/${this.orgID}/game/${this.gameID}/gameStatus/audioOption`)
    },
    value: {
      get() {
        if (
          this.$store.getters.gameStatus?.audioOption ===
          AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER
        )
          return Value.ALL

        return Value.AUTO
      },
      set(value) {
        if (value === Value.ALL)
          return this.ref.set(AudioOption.EVERYONE_CAN_HEAR_EACH_OTHER)

        this.ref.set(AudioOption.AUTO)
      }
    },
    items() {
      return items
    }
  }
})
